<!--
 * @Author: huwanjun
 * @Date: 2021-04-07 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-24 14:27:42
 * @Description: 新增产线侧抽屉组件
-->
<template>
  <NeoDrawerLayout :showFooter="true" class="AddProductionLine">
    <div class="step">
      <a-steps :current="step - 1" size="small">
        <a-step :title="$t('newPline.basic_setup')" />
        <a-step :title="$t('newPline.Neo_setup')" />
      </a-steps>
    </div>
    <!-- 第一步 -->
    <template v-if="step === 1">
      <div class="first-step">
        <NeoTitle :text="$t('newPline.title_basic_info')" class="mt-5 mb-3" />
        <a-form-model ref="oneForm" :colon="false" v-bind="layout.stepOne" :model="formData" :rules="rules">
          <!-- 产线名 -->
          <a-form-model-item prop="name" :label="$t('newPline.Pline_name')">
            <a-input v-model="formData.name" :placeholder="$t('newPline.Pline_name_holder')"></a-input>
          </a-form-model-item>
          <!-- 行业领域 -->
          <a-form-model-item prop="field" :label="$t('newPline.industry_field')">
            <a-cascader
              v-model="formData.field"
              :options="industryList"
              :fieldNames="fielName"
              :placeholder="$t('newPline.industry_field_holder')"
            ></a-cascader>
          </a-form-model-item>

          <NeoTitle :text="$t('newPline.title_product_dock')" class="mt-1" />
          <a-radio-group v-model="neoVersion">
            <a-space :size="80">
              <a-radio :value="1" class="mt-3 mb-3">{{ $t('newPline.dock_new_Neo') }}</a-radio>
              <a-radio :value="0" class="mt-3 mb-3">{{ $t('newPline.dock_Neo') }}</a-radio>
            </a-space>
          </a-radio-group>
          <NeoTitle :text="$t('newPline.title_offer_config')" class="mt-1" />
          <a-radio :defaultChecked="true" class="mt-3 mb-3"
            >{{ $t('newPline.default_offer_mode') }}：<span>{{ $t('newPline.artificial_offer') }}</span></a-radio
          >
          <div class="price-info">
            <!-- 报价配置 -->
            <template v-if="neoVersion === 0">
              <a-descriptions bordered layout="vertical" :column="9" size="small">
                <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
                  $t('PlineDetails.discount_ratio')
                }}</a-descriptions-item>
                <a-descriptions-item
                  v-for="(item, index) in formData.discountList"
                  :key="item.discountRange"
                  :label="item.discountRange"
                >
                  <a-form-model-item
                    style="margin-bottom: 0px"
                    class="table-item"
                    :prop="`discountList.${index}.discountValue`"
                    :rules="[validate.required, decimal_0_1]"
                  >
                    <a-input size="small" v-model="formData.discountList[index].discountValue"></a-input>
                  </a-form-model-item>
                </a-descriptions-item>
              </a-descriptions>
            </template>
            <template v-if="neoVersion === 1">
              <a-descriptions bordered layout="vertical" :column="11" size="small">
                <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
                  $t('PlineDetails.discount_ratio')
                }}</a-descriptions-item>
                <a-descriptions-item
                  v-for="(item, index) in formData.discountList_2"
                  :key="item.discountRange"
                  :label="item.discountRange"
                >
                  <a-form-model-item
                    style="margin-bottom: 0px"
                    class="table-item"
                    :prop="`discountList_2.${index}.discountValue`"
                    :rules="[validate.required, decimal_0_1]"
                  >
                    <a-input size="small" v-model="formData.discountList_2[index].discountValue"></a-input>
                  </a-form-model-item>
                </a-descriptions-item>
              </a-descriptions>
            </template>
          </div>
        </a-form-model>
      </div>
    </template>
    <!-- 第二步 -->
    <template v-if="step === 2 && neoVersion === 0">
      <div class="second-step second-step-special">
        <a-form-model ref="twoForm" v-bind="layout.stepTwo" :model="formData" :rules="rules" :colon="false">
          <NeoTitle :text="$t('newPline.title_basic_config')" class="mt-5 mb-3" />
          <div>
            <!-- client project -->
            <a-form-model-item
              prop="produceClientProject"
              :rules="[validate.required]"
              :label="$t('newPline.client_project')"
            >
              <a-select
                show-search
                v-model="formData.produceClientProject"
                :placeholder="$t('newPline.client_project_holder')"
                @change="handleChange"
                :labelInValue="true"
                :filter-option="filterOption"
              >
                <a-select-option v-for="item in clientProjectList" :key="item.pid">{{
                  `${item.projectName}(${item.pid})`
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- job type -->
            <a-form-model-item prop="produceJobType" :label="$t('newPline.job_type')">
              <a-select v-model="formData.produceJobType" :placeholder="$t('newPline.job_type_holder')">
                <a-select-option v-for="item in jobTypeList" :key="item.jobTypeCode">{{
                  item.jobTypeName
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- ratio -->
            <a-form-model-item :label="$t('newPline.ratio')" prop="produceRatio" :rules="[validate.required]">
              <a-select v-model="formData.produceRatio" :placeholder="$t('newPline.ratio_holder')" :labelInValue="true">
                <a-select-option v-for="(item, index) in ratioList" :key="index" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <NeoTitle :text="$t('newPline.title_corpus_config')" class="mt-1 mb-3" />
          <div class="corpus-config">
            <a-row>
              <a-col :span="18">
                <!-- 机翻引擎 -->
                <!-- prop="produceMtEngine" -->
                <!-- :rules="[validate.required]" -->
                <a-form-model-item
                  :label="$t('newPline.engine')"
                  :label-col="{ flex: '100px' }"
                  :wrapper-col="{ span: 18 }"
                >
                  <a-select
                    v-model="formData.produceMtEngine"
                    :labelInValue="true"
                    :placeholder="$t('newPline.engine_holder')"
                  >
                    <a-select-option v-for="item of mtEngineList" :key="item.code">{{ item.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6" :pull="1">
                <a-form-model-item>
                  <a-checkbox v-model="formData.produceMtApply">{{ $t('newPline.apply_result') }}</a-checkbox>
                </a-form-model-item>
              </a-col>
            </a-row>
            <div class="corpos">
              <a-table
                class="head-bg no-br mt-1"
                bordered
                :data-source="formData.documentList"
                :pagination="false"
                size="middle"
                rowKey="documentType"
              >
                <a-table-column :title="$t('newPline.doc_type')" key="documentType" width="25%">
                  <template slot-scope="text, record, index">{{ docType[index].dictLabel }}</template>
                </a-table-column>
                <!-- tm -->
                <a-table-column :title="$t('newPline.tm_and_term')" key="produceTmTermGroup" width="35%">
                  <template slot-scope="text, record, index">
                    <a-form-model-item
                      :prop="`documentList.` + index + `.produceTmTermGroup`"
                      :rules="[validate.required]"
                    >
                      <a-select
                        :placeholder="$t('newPline.tm_and_term_holder')"
                        size="small"
                        v-model="formData.documentList[index].produceTmTermGroup"
                        :labelInValue="true"
                      >
                        <a-select-option v-for="item in tmTermGroupList" :key="item.groupId" :value="item.groupId">{{
                          item.groupName
                        }}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                </a-table-column>
                <!-- tm反向应用 -->
                <a-table-column :title="$t('newPline.tm_reverse')" align="center" key="produceTmReverseApply">
                  <template slot-scope="text, record, index">
                    <a-switch v-model="formData.documentList[index].produceTmReverseApply" size="small"
                  /></template>
                </a-table-column>
              </a-table>
            </div>
          </div>
          <NeoTitle :text="$t('newPline.title_person_config')" class="mt-4 mb-3" />
          <div class="person-config">
            <a-form-model-item prop="supplierPm" :label="$t('newPline.delivery_manager')" v-bind="layout.item_long">
              <a-select
                show-search
                v-model="formData.supplierPm"
                :placeholder="$t('newPline.delivery_manager_holder')"
                :filter-option="filterOption"
              >
                <a-select-option v-for="item in pmList" :key="item.id" :value="item.id">{{
                  `${item.realName}(${item.email})`
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="produceManagerList"
              :label="$t('newPline.product_manager')"
              v-bind="layout.item_long"
            >
              <a-select
                mode="multiple"
                v-model="formData.produceManagerList"
                :placeholder="$t('newPline.product_manager_holder')"
                :filter-option="filterOption"
              >
                <a-select-option v-for="item in produceManagerList" :key="item.username">{{
                  `${item.displayName}(${item.username})`
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </template>
    <template v-if="step === 2 && neoVersion === 1">
      <div class="second-step-new">
        <a-form-model ref="twoForm" v-bind="layout.stepTwo" :model="newNeoForm" :rules="rules" :colon="false">
          <NeoTitle :text="$t('newPline.title_basic_info')" class="mt-3 mb-3" />
          <div class="basic_config">
            <a-row>
              <a-col>
                <a-form-model-item
                  :label="$t('newPline.program')"
                  :label-col="{ span: 2 }"
                  :wrapper-col="{ span: 22 }"
                  prop="program"
                  :rules="[validate.required]"
                  style="margin-right: 35px"
                >
                  <a-select
                    v-model="newNeoForm.program"
                    mode="multiple"
                    :filter-option="filterOption"
                    @change="onSelectProgram"
                  >
                    <a-select-option v-for="(item, index) in programList" :key="index" :value="item.id">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col style="margin-right: 35px" class="flexx">
                <a-form-model-item
                  :label="$t('newPline.new_program_client_project')"
                  :label-col="{ flex: '150px' }"
                  :wrapper-col="{ flex: 'auto' }"
                  prop="clientProject"
                  :rules="[validate.required]"
                >
                  <a-select v-model="newNeoForm.clientProject" style="flex: 1; display: inline-block">
                    <a-select-option v-for="item in namePidList" :key="item" :value="item.id">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                  <div class="question_mark_mod">
                    <a-tooltip placement="topLeft" :title="$t('newPline.tooltop')">
                      <img class="question_mark_mod_img" src="../../../../../assets/image/question_mark.png" alt="" />
                    </a-tooltip>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <div>
            <a-table
              class="head-bg no-br"
              :rowClassName="fontIsRed"
              bordered
              :pagination="pagination"
              size="middle"
              :data-source="tableData"
            >
              <a-table-column :title="$t('newPline.table_program')" :width="360">
                <template slot-scope="text">{{ text.name }}</template>
              </a-table-column>
              <a-table-column :title="$t('newPline.table_source')">
                <template slot-scope="text">{{ text.sourceCode }}</template>
              </a-table-column>
              <a-table-column :title="$t('newPline.table_content_type')" :width="300">
                <template slot-scope="text">{{ text.componentName }}</template>
              </a-table-column>
            </a-table>
          </div>
          <!-- 注意 -->
          <div class="attention">
            <p>{{ $t('newPline.attention') }}{{ $t('newPline.attention_1') }}</p>
            <!-- <p>{{ $t('newPline.attention_1') }}</p>
            <p>{{ $t('newPline.attention_2') }}</p>
            <p>{{ $t('newPline.attention_3') }}</p> -->
          </div>
          <div>
            <NeoTitle :text="$t('newPline.title_person_config')" class="mt-4 mb-2" />
            <a-form-model-item
              :label="$t('newPline.delivery_manager')"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 22 }"
              prop="supplierPm"
              :rules="[validate.required]"
            >
              <a-select
                show-search
                v-model="newNeoForm.supplierPm"
                :placeholder="$t('newPline.delivery_manager_holder')"
                :filter-option="filterOption"
              >
                <a-select-option v-for="item in pmList" :key="item.id" :value="item.id">{{
                  `${item.realName}(${item.email})`
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </template>
    <template v-slot:footer>
      <a-space :size="10">
        <a-button type="primary" @click="onNext" v-if="step === 1">{{ $t('next') }}</a-button>
        <a-button type="neo-btn-primary" @click="step = 1" v-if="step === 2">{{ $t('prev') }}</a-button>
        <a-button type="primary" :loading="loading" v-if="step === 2 && neoVersion === 0" @click="onSubmit">{{
          $t('submit')
        }}</a-button>
        <a-button type="primary" :loading="loading" v-if="step === 2 && neoVersion === 1" @click="onSubmitNewNeo">{{
          $t('submit')
        }}</a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>

<script>
import NeoDrawerLayout from '@/components/common/NeoDrawerLayout'
import NeoTitle from '@/components/common/NeoTitle'
import { mapState } from 'vuex'

const { validate } = window.$g

export default {
  name: 'AddProductionLine',
  components: { NeoDrawerLayout, NeoTitle },
  props: ['id', 'clientName'],
  data() {
    return {
      decimal_0_1: { pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^[0-1]$/g, message: this.$t('validate.decimal_0_1') },
      step: 1,
      validate: window.$g.validate,
      layout: {
        stepOne: {
          labelCol: { flex: '80px' },
          wrapperCol: { flex: 'auto' },
        },
        stepTwo: {
          labelCol: { flex: '100px' },
          wrapperCol: { flex: 'auto' },
        },
        item_long: {
          labelCol: { flex: '120px' },
          wrapperCol: { flex: 'auto' },
        },
      },
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      clientProjectList: [],
      jobTypeList: [],
      ratioList: [],
      mtEngineList: [],
      pmList: [],
      produceManagerList: [],
      tmTermGroupList: [],
      // 表格数据
      formData: {
        agencyId: '',
        name: '', //产线名
        field: undefined, //行业领域
        discountList: Array.from({ length: 8 }).map(() => ({
          discountRange: '',
          discountValue: '',
        })),
        discountList_2: Array.from({ length: 10 }).map(() => ({
          discountRange: '',
          discountValue: '',
          discountRangeMapping: '',
        })),
        produceClientProject: undefined, //Client Project
        produceJobType: undefined, //Job Type
        produceRatio: undefined, //Ratio
        produceMtEngine: undefined, //机翻引擎
        produceMtApply: false,
        supplierPm: undefined, //交付经理
        produceManagerList: [], //生产管理员
        documentList: Array.from({ length: 2 }).map(() => ({
          documentType: '',
          produceTmReverseApply: false,
          produceTmTermGroup: undefined,
        })),
      },
      newNeoForm: {
        program: undefined,
        clientProject: undefined,
        supplierPm: undefined,
      },
      rules: {
        name: [validate.size_20, validate.required], // 产线名
        field: [validate.required], // 行业领域
        produceClientProject: [validate.required],
        produceJobType: [validate.required],
        supplierPm: [validate.required],
        produceManagerList: [validate.required],
      },
      pagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 6,
        style: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        // eslint-disable-next-line no-unused-vars
        onChange: (page) => {
          this.pagination.current = page
        },
      },
      loading: false,
      neoVersion: 1,
      tableData: [],
      programList: [],
      namePidList: [],
      updateProgramLineRequestList: [],
      tableSameData: [],
    }
  },
  mounted() {
    console.log(`this.order_ratio`, this.order_ratio)
    console.log(`this.trans_order_ratio`, this.trans_order_ratio)
    this.order_ratio.forEach((item, index) => {
      this.formData.discountList[index].discountRange = item.dictLabel
      this.formData.discountList[index].discountValue = item.dictValue
    })
    this.trans_order_ratio.forEach((item, index) => {
      this.formData.discountList_2[index].discountRange = item.dictLabel
      this.formData.discountList_2[index].discountValue = item.dictValue
      this.formData.discountList_2[index].discountRangeMapping = item.remark
    })
    this.getClientProjectList()
    this.getJobTypeList()
    this.getMtEngineList()
    this.getProduceManagerList()
    this.getPmList()
    this.getProgramList()
    this.getNamePidList()
    this.formData.agencyId = this.id
  },
  computed: {
    ...mapState('app', {
      industryList: (state) => state.industryList,
      userInfo: (state) => state.userInfo,
    }),
    // 报价区间
    order_ratio: function () {
      const data = this.$store.getters['app/getDictByType']('ORDER_RATIO')
      return data
    },
    // 新版报价区间
    trans_order_ratio: function () {
      const data = this.$store.getters['app/getDictByType']('TRANS_2_ORDER_RATIO')
      return data
    },
    docType: function () {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },
  },
  methods: {
    fontIsRed(record) {
      const table = this.tableData
      const { id: myid, sourceCode, componentId } = record
      const isred = table.some((item) => {
        if (myid !== item.id) {
          return item.sourceCode === sourceCode && item.componentId === componentId
        } else {
          return false
        }
      })
      if (isred) {
        return 'redfont'
      } else {
        return ''
      }
    },
    onNext() {
      this.$refs.oneForm.validate((valid) => {
        if (valid) {
          this.step = 2
          this.docType.forEach((item, index) => {
            this.formData.documentList[index].documentType = item.dictValue
          })
        } else {
          return false
        }
      })
    },
    onSubmit() {
      // 提交按钮的响应事件
      this.$refs.twoForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const proMt = this.formData.produceMtApply ? 1 : 0
            const data = await this.$http('newProductionLine', {
              ...this.formData,
              documentList: this.formData.documentList.map((item) => ({
                documentType: item.documentType,
                produceTmReverseApply: item.produceTmReverseApply ? 1 : 0,
                produceTmTermGroup: item.produceTmTermGroup.key,
                produceTmTermGroupName: item.produceTmTermGroup.label,
              })),
              field: this.formData.field[this.formData.field.length - 1],
              produceVersion: 1,
              quoteType: 1,
              produceMtApply: proMt,
              produceJobMode: '1',
              produceClientProject: this.formData.produceClientProject.key,
              produceClientProjectName: this.formData.produceClientProject.label,
              produceMtEngine: this.formData?.produceMtEngine?.key || '',
              produceMtEngineName: this.formData?.produceMtEngine?.label || '',
              produceRatio: this.formData.produceRatio.key == -1 ? null : this.formData.produceRatio.key,
              produceRatioName: this.formData.produceRatio.label,
              produceManagerList: this.formData.produceManagerList.map((item) => {
                let data = []
                this.produceManagerList.forEach((v) => {
                  if (v.username === item) {
                    let result = {}
                    result.producePm = v.username
                    result.producePmEmails = v.emails
                    result.producePmName = v.displayName
                    data.push(result)
                  }
                })
                return data[0]
              }),
            })
            this.loading = false
            data && this.$emit('show')
            this.$message.success(this.$t('prompt.create_success'))
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        } else {
          return false
        }
      })
    },
    // 新版添加产线
    onSubmitNewNeo() {
      const table = this.tableData
      const arr = []
      console.log(`table`, table)
      if (table.length >= 2) {
        table.forEach((item1) => {
          const isSame = table.some((item2) => {
            if (item1.id !== item2.id) {
              return item2.sourceCode === item1.sourceCode && item2.componentId === item1.componentId
            } else {
              return false
            }
          })
          if (isSame) {
            arr.push(item1)
          }
        })
        console.log(`arr`, arr)
        if (arr.length > 0) {
          let tooltop = ''
          let names = ''
          arr.forEach((item, idx) => {
            if (idx === 0) {
              names = `"${item.name}"`
            } else {
              names = names + '、' + `"${item.name}"`
            }
          })
          tooltop = names
          console.log(`tooltop：`, tooltop)
          return this.$message.error(this.$t('newPline.source_content', { tooltop }), 5)
        }
      }
      this.$refs.twoForm.validate(async (valid) => {
        if (valid) {
          // 校验Source和Content Type是否有相同
          try {
            this.loading = true
            const params = {
              agencyId: this.formData.agencyId,
              name: this.formData.name,
              pid: this.newNeoForm.clientProject,
              field: this.formData.field[this.formData.field.length - 1],
              supplierPm: this.newNeoForm.supplierPm,
              produceVersion: 2,
              quoteType: 1,
              discountList: this.formData.discountList_2,
              updateProgramLineRequestList: this.updateProgramLineRequestList.map((item, index) => {
                return Object.assign(item, { pid: this.newNeoForm.program[index] })
              }),
            }
            console.log(`params`, params)
            const data = await this.$http('newProductionLine_2', params)
            this.loading = false
            data && this.$emit('show')
            this.$message.success(this.$t('prompt.create_success'))
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 获取clientProject列表
    async getClientProjectList() {
      try {
        const data = await this.$http('clientProjectList', {
          clientName: this.clientName,
        })
        this.clientProjectList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取jobType列表
    async getJobTypeList() {
      try {
        const data = await this.$http('jobTypeList')
        this.jobTypeList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    handleChange(val) {
      this.getRatioList(val.key)
      this.getTmTermGroupList(val.key)
      this.formData.produceRatio = undefined
      this.formData.documentList.forEach((item) => {
        item.produceTmTermGroup = undefined
      })
    },
    // 获取ratio列表
    async getRatioList(val) {
      try {
        const data = await this.$http('ratioList', {
          pid: val,
        })
        // TODO：将map修改为forEach
        this.ratioList = data.filter((item) => {
          if (item.name == 'System Default') {
            item.id = -1
          }
          return item.name
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取 mtEngine列表
    async getMtEngineList() {
      try {
        const data = await this.$http('mtEngineList')
        this.mtEngineList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取交付经理
    async getPmList() {
      try {
        const data = await this.$http('getPmList')
        this.pmList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取生产管理员
    async getProduceManagerList() {
      try {
        const data = await this.$http('getproduceManagerList')
        this.produceManagerList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取TM & Term 组的数据
    async getTmTermGroupList(val) {
      try {
        const data = await this.$http('tmTermGroupList', {
          pid: val,
        })
        this.tmTermGroupList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取program列表
    async getProgramList() {
      try {
        const data = await this.$http('programList', {
          agencyId: this.id,
          productId: '',
        })
        this.programList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 对接鲸译-获取clientproject
    async getNamePidList() {
      try {
        const data = await this.$http('namePidList', {
          name: this.clientName,
        })
        this.namePidList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 搜索下拉
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onSelectProgram(value) {
      this.tableData = []
      this.updateProgramLineRequestList = []
      value.forEach((val) => {
        // let that = this
        let result = this.programList.filter((item) => {
          if (item.id === val) {
            this.updateProgramLineRequestList.push({
              pid: undefined,
              programId: item.id,
              contentType: item.componentId,
              source: item.sourceCode,
              programName: item.name,
            })
            return item
          }
        })
        this.tableData.push(...result)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.AddProductionLine {
  /deep/.ant-form-item {
    display: flex;
    margin-bottom: 15px;
  }
  .step {
    /deep/.ant-steps {
      width: 60%;
      margin-top: 5px;
    }
  }
  .corpos {
    /deep/.ant-form-item {
      margin-bottom: 0;
    }
    .table-item {
      /deep/.ant-form-item-control {
        height: auto;
        line-height: normal;
      }
      /deep/.ant-form-explain {
        margin-top: 5px;
      }
      /deep/.ant-input {
        border: none;
        text-align: center;
        padding: 0;
      }
    }
  }
  .price-info {
    /deep/.ant-descriptions-item-label {
      text-align: center;
      color: @text_color_2;
    }
    .table-item {
      /deep/.ant-form-item-control {
        height: auto;
        line-height: normal;
      }
      /deep/.ant-form-explain {
        margin-top: 5px;
      }
      /deep/.ant-input {
        border: none;
        text-align: center;
        padding: 0;
      }
    }
  }
  .second-step-new {
    .help {
      font-size: 20px;
      cursor: pointer;
      color: #d4dae5;
    }
    .attention {
      margin-top: 50px;
      p {
        margin: 0;
        color: #f56c6c;
      }
    }
    .basic_config {
      /deep/.ant-form-item {
        margin-bottom: 15px;
      }
      /deep/.ant-col-7 {
        width: 28%;
      }
      /deep/.ant-col-17 {
        width: 72%;
      }
    }
    .btn-container {
      margin-top: 44px;
      .submit-btn {
        min-width: 100px;
      }
    }
    /deep/.ant-empty-normal {
      margin: 0 !important;
    }
  }
  //设置padding
  ::v-deep .ant-table-body {
    tr > th:first-child {
      padding-left: 45px !important;
    }
    // tr > th:last-child {
    //   padding-right: 45px !important;
    // }
    .ant-table-tbody {
      & > tr > td:first-child {
        padding-left: 45px !important;
      }
      & > tr > td:last-child {
        padding-right: 45px !important;
      }
      .redfont {
        color: #f56c6c;
      }
    }
  }
  .second-step-special {
    /deep/ .ant-col.ant-form-item-label {
      flex: 0 0 130px !important;
    }
    /deep/ .ant-col-pull-1 {
      right: 2.166667%;
    }
  }
  .question_mark_mod {
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    &_img {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
  .flexx {
    line-height: 39px;
    /deep/.ant-form-item-children {
      display: flex;
      width: 676px;
    }
    /deep/.ant-col.ant-col-19.ant-form-item-control-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
</style>
